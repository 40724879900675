.navDesktop {
  composes: block from global;
  composes: md-down_hidden from global;
  composes: w-full from global;
}

.navMobile {
  composes: hidden from global;
  composes: md-down_block from global;
  composes: w-full from global;
  composes: text-center from global;
}

.navbar {
  composes: flex from global;
  composes: flex-row from global;
  composes: flex-no-wrap from global;
  composes: justify-between from global;
  composes: content-between from global;
}

.active {
  composes: font-bold from global;
}

.active,
.navlink:hover {
  color: #fff;
}
