// 640x480
.carousel {
  composes: relative from global;
  composes: overflow-none from global;
  composes: bg-default from global;
  composes: secondaryRoman-20 from global;
  composes: lg-down_secondaryRoman-10 from global;
  composes: m-auto from global;
}

.number {
  composes: absolute from global;
  composes: top-0 from global;
  composes: right-0 from global;
  composes: w-1/12 from global;
  composes: lg-down_w-12 from global;
  composes: text-center from global;
  composes: bg-overlay from global;
  padding: 8px 0;
}

.caption {
  //composes: absolute bottom-0 from global;
  composes: text-center from global;
  composes: bg-overlay from global;
  composes: w-full from global;
  padding: 8px 12px;
}

.closeButton {
  composes: absolute top-0 right-0 m-2 from global;

  &:hover {
    opacity: 0.5;
  }
}

.prev,
.next {
  composes: absolute w-auto p-6 lg-down_p-4 from global;
  top: 50%;
  cursor: pointer;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;

  &:hover {
    background-color: rgba(231, 231, 231, 0.8);
  }
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.slide {
  composes: m-auto h-qtr-screen from global;
}

.fadeIn {
  composes: block text-gray-light from global;
  animation-name: fade;
  animation-duration: 1.25s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
