.wrapper {
  composes: small-12 from global;
  composes: bg-gray-dark from global;
}

.container {
  composes: row from global;
  composes: mt-3 from global;
  composes: mb-6 from global;
  composes: px-6 from global;
}

.leader {
  composes: primaryBold from global;
  composes: primaryBold-40 from global;
  composes: text-gray-light from global;
  composes: mt-3 p-2 from global;
}

.social {
  composes: flex from global;
  composes: flex-row from global;
  composes: flex-no-wrap from global;
  composes: justify-between from global;
  composes: content-between from global;
  composes: ml-auto from global;
}

.icon {
  composes: mt-5 from global;

  &:hover {
    opacity: 0.85;
  }
}

.navWrapper {
  composes: primaryRoman from global;
  composes: primaryRoman-20 from global;
  composes: sm-only_primaryRoman-10 from global;
  composes: text-gray-medium from global;
  composes: py-4 from global;
  composes: sm-only_py-1 from global;
  composes: border-t-2 from global;
  composes: border-b-2 from global;
  composes: border-gray-medium from global;
}

.navContainer {
  composes: row from global;
  composes: my-3 from global;
  composes: px-9 from global;
}
